import PropTypes from "prop-types";
import React from "react";

import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Login from "../components/login/Login";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";

function LoginPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps"]}
          location={location}
          title="Connexion"
        />
        <Page noHeader>
          <Login />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

LoginPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default LoginPage;
